import React, { Fragment, useEffect, useContext, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { LayoutContext } from "../layout";
import { getQuadrantStores } from "../../superAdmin/selectstores/FetchApi";
// import { isWishReq, unWishReq, isWish } from "./Mixins";


const SelectStores = (props) => {
  const { data, dispatch } = useContext(LayoutContext);
  const { selectStores } = data;
  const history = useHistory();
  const dotRefs = useRef([]);
  const storeIndex = ["store1","store2","store3","store4"]

  const [currentStore, setCurrentStore] = useState(0);

  const fetchData = async () => {
    dispatch({ type: "loading", payload: true });
    try {
      console.log("line_17");
      let responseData = await getQuadrantStores();
      console.log("resp_18:",responseData);
      setTimeout(() => {
        if (responseData && responseData.Stores) {
          dispatch({ type: "setSelectStores", payload: responseData.Stores });
          dispatch({ type: "loading", payload: false });
        }
      }, 500);

    } catch (error) {
      console.log(error);
    }
  };
  const [timer, setTimer] = useState(0);
  useEffect(() => {
    setCurrentStore(currentStore < 3 ? currentStore + 1 : 0 )
   setTimeout(()=>{
    setTimer(timer+1)
   },5000)
  }, [timer]);
  
  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    if (dotRefs.current[currentStore]) {
      // console.log("Ref for current store:", dotRefs.current[currentStore]);
      // Access the current element and perform actions
      {["0","1","2","3"].map((item,index)=>{
        {["0","1","2","3"].map((item,jndex)=>{

          dotRefs.current[index][jndex].style.backgroundColor = "rgba(255, 255, 255, 0)";
        } )}
  
        dotRefs.current[index][currentStore].style.backgroundColor = "black";
        
      } )}
    }
  }, [currentStore]);

  if (data.loading) {
    return (
      <div className="col-span-2 md:col-span-3 lg:col-span-5 flex items-center justify-center py-24">
        <svg
          className="w-12 h-12 animate-spin text-gray-600"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
          ></path>
        </svg>
      </div>
    );
  }


  return (
    <Fragment>
      {selectStores && selectStores.length > 0 ? (
        selectStores.map((item, strindex) => {
        return(
          <Fragment>
            <div 
              className="flex w-halfScreen bg-blue-100 overflow-hidden" 
              onClick={(e) => history.push(`/stores/${item[storeIndex[currentStore]].sName}`)}
            >
              <img
                
                className="w-full object-cover object-center cursor-pointer "
                src={ item[`${storeIndex[currentStore]}`]? `${item[`${storeIndex[currentStore]}`].url}`: null}
                alt="store"
              />
              <div className=" text-2xl absolute m-4 mt-16 md:m-16 md:mt-100 lg:mt-100 items-center justify-center  bg-opacity-50 text-white md:text-6xl font-bold overflow-hidden">
                <p style={{ cursor: 'pointer'}}>
                  {item[`${storeIndex[currentStore]}`]? item[`${storeIndex[currentStore]}`].sName : null}
                </p>
              </div>
              <div className="dotsContainer absolute ">
                {["0","1","2","3"].map((item,index)=>(
                  <div className="dotBtns" ref={(el) => {
                    if (!dotRefs.current[strindex]) {
                      dotRefs.current[strindex] = []; // Initialize row if not already done
                    }
                    dotRefs.current[strindex][index] = el
                  }}></div>
                ))}
              </div>

            </div>
          </Fragment>
        )
        }
      )):(null)}

      {/* {selectStores && selectStores.length > 0 ? (
        selectStores.map((item, index) => {
          return (
            <Fragment key={index}>
              <div className="store_banner relative col-span-1 m-2 pr_card justify-between flex flex-col overflow-hidden">
                <div className="flex flex-col justify-center items-center overflow-hidden" style={{height:"100%"}}>
                  <img
                    onClick={(e) => history.push(`/stores/${item.sName}`)}
                    className="w-full object-cover object-center cursor-pointer img_magnify"
                    src={`${item.url}`}
                    alt="store"
                  />
                </div>
                <div className="flex items-center justify-between mt-2 p-4 pb-8 shop_bottom">
                  <div className="text-gray-600 font-light truncate">
                    {item.sName}
                  </div>
                  <div className="flex items-center space-x-1">
                    <span>
                      <svg
                        className="w-4 h-4 fill-current text-yellow-700"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
                        />
                      </svg>
                    </span>
                    <span className="text-gray-700">
                      {item.sRatingsReviews.length}
                    </span>
                  </div>
                </div>

              </div>
            </Fragment>
          );
        })
      ) : (
        <div className="col-span-2 md:col-span-3 lg:col-span-5 flex items-center justify-center py-24 text-2xl">
          No store found
        </div>
      )} */}
    </Fragment>
  );
};

export default SelectStores;
