import React, { Fragment, useReducer, useEffect, useState} from "react";
import Layout, { LayoutContext } from "../layout";
import Slider from "./Slider";
import SingleProduct from "./SingleProduct";
import Blog from "./blog";
import Testimonials from "./Testimonials";
import { layoutReducer, layoutState } from "../layout/layoutContext";
import { useParams } from "react-router-dom";
import { getStore } from "../../admin/categories/FetchApi";
import AsyncLocalStorage from '@createnextapp/async-local-storage'

import CatBar from "./CatBar";
import Store_Intro from "./Store_Intro";


const HomeComponent = () => {
  return (
    <Fragment>
      <div className="l1 flex flex-row">
        <Store_Intro/>
        <div className="navber">
          <Slider />
          {/* Category, Search & Filter Section */}
          <CatBar/>
          <section className="m-1 md:mt-2 md:my-1 p-2 mx-8 pd:px-8 pd:py-2  bstslr">
            Our Bestsellers Of The Month! 
          </section>
          {/* Product Section m-2 md:mx-4 md:my-2 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 */}
          <section className="md:m-2 md:mx-4 md:my-2 flex flex-row justify-around grid grid-cols-3 md:grid-cols-3 lg:grid-cols-5 w-full">
            <SingleProduct />
          </section>
        </div>
      </div>
      <section className="m-4">
        <Blog/>
      </section>
      <section className="m-4">
          <Testimonials/>
      </section>
      
    </Fragment>
  );
};

const Home2 = (props) => {
  const [data, dispatch] = useReducer(layoutReducer, layoutState);
  const {storeName} = useParams();
  const [store, setStore] = useState(null);
  const fetchData = async () => {
    let responseData = await getStore(storeName);
    if(responseData && responseData.Store){
      dispatch({ type: "setStore", payload: responseData.Store });
      AsyncLocalStorage.setItem(storeName, JSON.stringify(responseData));
      setStore(responseData);
    }
  };
  
  useEffect(() => {
    fetchData();
  }, [data.storeData._id]);
  
  return (
    <Fragment>
      <LayoutContext.Provider value={{ data, dispatch }}>
        <Layout children={<HomeComponent />} />
      </LayoutContext.Provider>
    </Fragment>
  );
};

export default Home2;