import React from 'react';
import axios from "axios";
const apiURL = process.env.REACT_APP_API_URL;

export const logout = async () => {

  localStorage.removeItem("jwt");
  const responseData = await axios.get(`${apiURL}/api/auth/logout`, {
    withCredentials: true,
  })
  console.log("response : ", responseData)
  window.location.href = "/home";
};

