import axios from "axios";
import React, { Fragment, useEffect, useReducer, useState } from "react";
import Routes from "./components";
import { LayoutContext, layoutState, layoutReducer } from "./components/market";
const apiURL = process.env.REACT_APP_API_URL;



function App() {
//   const [user, setUser] = useState(null);

 
  useEffect(()=>{
	 async function checkIfUserIsLoggedIn(){
		
		try{
			const responseData =  await axios.get(`${apiURL}/api/check-is-logged-in`, {
				withCredentials: true, 
			  })
			if(responseData.status===200){
				localStorage.setItem("jwt","LoggedIn")
			}
		}catch(err){
			console.log(err)
			localStorage.removeItem("jwt")
		}
	}
	checkIfUserIsLoggedIn();
},[])
  
	useEffect(() => {
		async function getMe() {
			try{
				if(localStorage.getItem("jwt")){
					let responseData  = await axios
					.get(`${apiURL}/api/auth/me`, {
					withCredentials: true,
					})
					.then((res)=> {
					return res;
					});
					var userData = null;
					if(responseData.data && responseData.data.user){
						userData = responseData.data.user;
					}
					if (responseData.data.token) {
						localStorage.setItem("jwt", JSON.stringify(responseData.data));
					}
				}
			} catch (err) {
				console.log(err);
			}
		}
		getMe();
	}, []);


  const [data, dispatch] = useReducer(layoutReducer, layoutState);
  return (
    <Fragment>
      <LayoutContext.Provider value={{ data, dispatch }}>
        <Routes />
      </LayoutContext.Provider>   
    </Fragment>
  );
}

export default App;