import React, { Fragment, useEffect, useContext, useState } from "react";
import OrderSuccessMessage from "./OrderSuccessMessage";
import { sliderImages } from "../../admin/dashboardAdmin/Action";
import { prevSlide, nextSlide } from "./Mixins";
import { LayoutContext } from "../../market";
import AsyncLocalStorage from '@createnextapp/async-local-storage'
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const apiURL = process.env.REACT_APP_API_URL;


const Slider = (props) => {
  const { data, dispatch } = useContext(LayoutContext);
  const [slide, setSlide] = useState(0);


  const {storeName} = useParams();
  const [storeId, setStoreId] = useState({});

  const fetchstoreId = async ()=>{
    setStoreId(JSON.parse(await AsyncLocalStorage.getItem(storeName))
    ? JSON.parse(await AsyncLocalStorage.getItem(storeName)).Store._id
    : "")
  }

  useEffect(() => {
   fetchstoreId();
  }, []);


  useEffect(() => {
    sliderImages(dispatch,storeId);
  }, [storeId]);

  return (
    <Fragment>
      <div className="relative bg-gray-100 rounded-2xl">
        {data.sliderImages.length > 0 ? (
          <img
            className=" rounded-2xl h-90"
            src={`${data.sliderImages[slide].url}`}
            alt="sliderImage"
          />
        ) : (
          ""
        )}
        <svg
          onClick={(e) => prevSlide(data.sliderImages.length, slide, setSlide)}
          className={`z-10 absolute top-40 left-0 mt-0 flex justify-end items-center box-border flex justify-center w-12 h-12 text-gray-700  cursor-pointer hover:text-yellow-700`}
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M15 19l-7-7 7-7"
          />
        </svg>
        <svg
          onClick={(e) => nextSlide(data.sliderImages.length, slide, setSlide)}
          className={`z-10 absolute top-40 right-0 mt-0 flex justify-start items-center box-border flex justify-center w-12 h-12 text-gray-700 cursor-pointer hover:text-yellow-700`}
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M9 5l7 7-7 7"
          />
        </svg>
        {/* <div className="absolute inset-0 flex items-center justify-center">
          <a
            href="#shop"
            style={{ background: "#303031" }}
            className="cursor-pointer box-border text-2xl text-white px-4 py-2 rounded"
          >
            Shop Now
          </a>
        </div> */}
      </div>
      <OrderSuccessMessage />
    </Fragment>
  );
};

export default Slider;
