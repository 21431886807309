import React, { Fragment, useReducer, useState } from "react";
import Slider from "./Slider";
import SingleProduct from "./SingleProduct";
import Blog from "./blog";
import Testimonials from "./Testimonials";
import SingleStore from "./Store";
import Layout, { LayoutContext } from "../layout";
import { layoutReducer, layoutState } from "../layout/layoutContext";
import CatBar from "./CatBar";
import { Button } from "@material-ui/core";
import SelectStores from "./SelectStores";
import StoreWiseProduct from "./StoreWiseProduct";
import StoreNew from "./StoreNew";

// export const HomeContext  = createContext();

const HomeComponent = () => {
  const [btnPress, setBtnPress] = useState(false);
  const loadMoreButtonPress = () => {
    setBtnPress(true);
  };
  return (
    <Fragment>
      <Slider />
      <div className="navber_main" style={{background: "#FBEBDB"}}>
        {/* Category, Search & Filter Section */}
        {/* <section className="m-4 md:mx-8 md:my-6">
          <ProductCategory />
        </section> */}
        {/* <CatBar/> */}
        <section className=" grid grid-cols-2 grid-rows-2 bg-black-100 ">
          <SelectStores/>
        </section>
        <section className="m-2 md:mx-4 md:my-2" style={{backgroundColor: "#D9D9D9"}}>
          <StoreNew />
        </section>
        {/* Category, Search & Filter Section */}
        <section className="m-1 md:mt-2 md:my-1 p-2 pd:px-8 pd:py-2  bstslr">
          Our Bestsellers Of The Month!
        </section>
        {/* Product Section */}
        {/* <section className="m-2 md:mx-4 md:my-2 grid grid-cols-3 md:grid-cols-3 lg:grid-cols-5">
          <SingleProduct btnPress={btnPress} setBtnPress={setBtnPress} />
        </section> */}
        <section className="m-2 md:mx-4 md:my-2 grid grid-cols-3 md:grid-cols-3 lg:grid-cols-5">
          <SingleStore />
        </section>
        <section className="flex-col">
          <StoreWiseProduct storeId="677a98aec8908314c0e65a04" position="2"/> 
          <StoreWiseProduct storeId="677ac180379ad78118b2cc2a" position="1"/>
        </section>
         {/* <section className="m-1 md:mt-2 md:my-1  pd:px-8 pd:py-2  bstslr">
          Explore our Stores and Designers!
        </section> */}
        {/* Product Section */}
        
        {/* ///////////////////////////Button for load more//////////////////// */}
        {/* <Button className="bstslr" onClick={loadMoreButtonPress}>
          {" "}
          Load more
        </Button> */}
      </div>
      {/* <RightBar/> */}
      <section className="m-4 mt-0">
        <Blog/>
      </section>

      {/* <section className="m-4">
        <Testimonials/>
      </section> */}
    </Fragment>
  );
};

const MarketHome = (props) => {
  const [data, dispatch] = useReducer(layoutReducer, layoutState);
  return (
    <Fragment>
      <LayoutContext.Provider value={{ data, dispatch }}>
        <Layout children={<HomeComponent />} />
      </LayoutContext.Provider>
    </Fragment>
  );
};

export default MarketHome;
