import React, { Fragment, useContext, useState, useEffect, useRef } from "react";
import { ProductContext } from "./index";
import { createProduct, getAllProduct, linkProduct } from "./FetchApi";
import { getAllStore } from "../stores/FetchApi";
import { CatBySec} from "../../admin/categories/FetchApi";
import { Button } from "@material-ui/core";
import { SecByStore } from "../sections/FetchApi";
import Select from "react-select";

const AddProductModal = (props) => {
  const { data, dispatch } = useContext(ProductContext);
  const selectInputRef = useRef();
  const [stores, setAllStore] = useState([]);
  const [sections, setAllSection] = useState([]);
  const [categories, setCategories] = useState([]);
  const [categoryId, setCategoryId] = useState();
  const [storeId, setStoreId] = useState(null);
  const [sectionId, setSectionId] = useState(null);
  const [sizes, setSizes] = useState({
    size: '',
    quantity: "",
  });
  const [similarOptions, setSimilarOptions] = useState([]);

  useEffect(() => {
    if (data.products) {
      const options = data.products.map(product => ({
        label: product.pName,
        value: {id:product._id, name:product.pName, image: product.pImages},

      }));
      setSimilarOptions(options);
    }
  }, [data.products]);
 
 
  const fetchStoreData = async () => {
    let responseData = await getAllStore();
    if (responseData.Stores) {
      setAllStore(responseData.Stores);
    }
  };

  const fetchSecs = async (storeId) => {
    let responseData = await SecByStore(storeId);
    if (responseData && responseData.Sections) {
      const optionSections = responseData.Sections.map(section => ({
        value: section._id,
        label: section.secName
      }));
      setAllSection(optionSections);
    }
  };

  const handleStoreChange = (selectedOption) => {
    setStoreId(selectedOption);
    setSectionId(null);
    fetchSecs(selectedOption.value); 
    setFdata({
      ...fData,
      pStore: selectedOption.value,
      pSection: "",
    });
  };

  const handleSectionChange = (selectedOption) => {
    setSectionId(selectedOption);
    setFdata({
      ...fData,
      pSection: selectedOption.value,
    });
    fetchCategories(selectedOption.value); 
  };
  


  const fetchCategories = async (secId) => {
    let responseData = await CatBySec(secId);
    if (responseData && responseData.Categories) {
      const optionCategories = responseData.Categories.map(category => ({
        value: category._id,
        label: category.cName,
      }));
      setCategories(optionCategories);
    }
  };
  
const optionsStore = stores.map(store => ({
  value: store._id,
  label: store.sName
}));


const handleCategoryChange = (selectedOption) => {
  setCategoryId(selectedOption);
  setFdata({ ...fData, pCategory: selectedOption.value });
};


const handleSimilarChange = (selectedOptions) => {
  setFdata({
    ...fData,
    similarProducts: selectedOptions ? selectedOptions.map(option => option.value) : [],
  });
};


useEffect(() => {
  fetchStoreData();
}, []);


  const alert = (msg, type) => (
    <div className={`bg-${type}-200 py-2 px-4 w-full`}>{msg}</div>
  );

  const [fData, setFdata] = useState({
    pName: "",
    pDescription: "",
    pStatus: "Active",
    pImage: null, // Initial value will be null or empty array
    pCategory: "",
    pStore: "",
    pSection: "",
    pPrice: "",
    pOffer: 0,
    pQuantity:[],
    similarProducts:[],
    success: false,
    error: false,
  });


  const fetchData = async () => {
    let responseData = await getAllProduct();
    setTimeout(() => {
      if (responseData && responseData.Products) {
        dispatch({
          type: "fetchProductsAndChangeState",
          payload: responseData.Products,
        });
      }
    }, 1000);
  };




  const submitForm = async (e) => {
    e.preventDefault();
    e.target.reset();
    
    
    fData.pQuantity = fData.pQuantity[0] ? JSON.stringify(fData.pQuantity) : "" ;
    fData.similarProducts = fData.similarProducts[0] ? JSON.stringify(fData.similarProducts) : "" ;
    fData.pStore = storeId ? storeId.value : "";
    fData.pSection = sectionId ? sectionId.value : "";
    fData.pCategory = categoryId ? categoryId.value : "";
    
  
    if (!fData.pImage || fData.pImage.length < 2) {
      setFdata({ ...fData, error: "Please upload at least 2 images" });
      setTimeout(() => {
        setFdata({ ...fData,
          pQuantity: [],
          similarProducts: [],
           error: false 
        });
      }, 2000);
      return;
    }
    selectInputRef.current.clearValue();
  
    try {
      let responseData = await createProduct(fData);
      
      if (responseData.success) {
        fetchData();
        let productData = {
          name: responseData.data.pName,
          id: responseData.data._id,
          image: responseData.data.pImages,
        };
        linkProduct(fData.similarProducts, productData);
  
        setFdata({
          ...fData,
          pName: "",
          pDescription: "",
          pImage: null,
          pStatus: "Active",
          pCategory: "",
          pSection: "",
          pPrice: "",
          pQuantity: [],
          similarProducts: [],
          pOffer: 0,
          success: responseData.success,
          error: false,
        });
  
        setTimeout(() => {
          setFdata({
            ...fData,
            success: false,
            error: false,
          });
        }, 2000);
      } else if (responseData.error) {
        setFdata({ ...fData, success: false, error: responseData.error });
        setTimeout(() => {
          setFdata({ ...fData, error: false, success: false });
        }, 2000);
      }
    } catch (error) {
      console.log("Error creating product:", error);
      setFdata({ ...fData, error: "Product creation failed. Please try again." });
      setTimeout(() => {
        setFdata({ ...fData, error: false });
      }, 2000);
    }
  };
  


  useEffect(() => {
    fetchData().then(() => {
      if (data.products) {
        const options = data.products.map(product => ({
          label: product.pName,
          value: product._id,
        }));
        setSimilarOptions(options);
      }
    });
  }, []);

  const handleAddQuantity = (size, quantity) => {
    setFdata(prevState => ({
      ...prevState,
      pQuantity: [...prevState.pQuantity, { size, quantity }]
    }));
  };
  
  const handleRemoveQuantity = (index) => {
    setFdata(prevState => {
      const updatedQuantity = [...prevState.pQuantity];
      updatedQuantity.splice(index, 1);
      return { ...prevState, pQuantity: updatedQuantity };
    });
  };
  
  if (!Array.isArray(fData.pQuantity)) {
    fData.pQuantity = [];
  }
  

  return (
    <Fragment>
      {/* Black Overlay */}
      <div
        onClick={(e) => dispatch({ type: "addProductModal", payload: false })}
        className={`${
          data.addProductModal ? "" : "hidden"
        } fixed top-0 left-0 z-30 w-full h-full bg-black opacity-50`}
      />
      {/* End Black Overlay */}

      {/* Modal Start */}
      <div
        className={`${
          data.addProductModal ? "" : "hidden"
        } fixed inset-0 flex items-center z-30 justify-center overflow-auto`}
      >
        <div className="mt-32 md:mt-0 relative bg-white w-11/12 md:w-3/6 shadow-lg flex flex-col items-center space-y-4 px-4 py-4 md:px-8">
          <div className="flex items-center justify-between w-full pt-4">
            <span className="text-left font-semibold text-2xl tracking-wider">
              Add Product
            </span>
            {/* Close Modal */}
            <span
              style={{ background: "#303031" }}
              onClick={(e) =>
                dispatch({ type: "addProductModal", payload: false })
              }
              className="cursor-pointer text-gray-100 py-2 px-2 rounded-full"
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </span>
          </div>
          {fData.error ? alert(fData.error, "red") : ""}
          {fData.success ? alert(fData.success, "green") : ""}
          <form className="w-full" onSubmit={(e) => submitForm(e)}>
            <div className="flex space-x-1 py-4">
              <div className="w-1/2 flex flex-col space-y-1 space-x-1">
                <label htmlFor="name">Product Name *</label>
                <input
                  value={fData.pName}
                  onChange={(e) =>
                    setFdata({
                      ...fData,
                      error: false,
                      success: false,
                      pName: e.target.value,
                    })
                  }
                  className="px-4 py-2 border focus:outline-none"
                  type="text"
                />
              </div>
              <div className="w-1/2 flex flex-col space-y-1 space-x-1">
                <label htmlFor="price">Product Price *</label>
                <input
                  value={fData.pPrice}
                  onChange={(e) =>
                    setFdata({
                      ...fData,
                      error: false,
                      success: false,
                      //pPrice: e.target.value,
                      pPrice: Math.round(parseFloat(e.target.value))
                    })
                  }
                  type="number"
                  className="px-4 py-2 border focus:outline-none"
                  id="price"
                />
              </div>
            </div>
            <div className="flex flex-col space-y-2">
              <label htmlFor="description">Product Description *</label>
              <textarea
                value={fData.pDescription}
                onChange={(e) =>
                  setFdata({
                    ...fData,
                    error: false,
                    success: false,
                    pDescription: e.target.value,
                  })
                }
                className="px-4 py-2 border focus:outline-none"
                name="description"
                id="description"
                cols={5}
                rows={2}
              />
            </div>
            {/* Most Important part for uploading multiple image */}
            <div className="flex space-x-1 py-4">
              <div className=" w-1/3 flex flex-col space-y-1">
                <label htmlFor="image">Product Images *</label>
                <span className="text-gray-600 text-xs">Must need 2 images</span>
                <input
                  onChange={(e) =>
                    setFdata({
                      ...fData,
                      error: false,
                      success: false,
                      pImage: [...e.target.files],
                    })
                  }
                  type="file"
                  accept=".jpg, .jpeg, .png"
                  className="px-4 py-2 border focus:outline-none"
                  id="image"
                  multiple
                />
              </div>
              <div className="w-1/3 flex flex-col space-y-1">
                <label htmlFor="quantity">Product in Stock *</label>
                <div className="flex flex-row">
                  {fData.pQuantity && fData.pQuantity.length > 0 
                    ?  (fData.pQuantity.map((elem) => {
                      return (
                        <div className= "bg-gray-400 mr-2 rounded flex flex-row text-center">
                          {elem.size} : {elem.quantity}
                          <div 
                            className=" rounded-full px-1 m-1 bg-white"
                            style={{fontSize: "1vh"}}
                            onClick={()=> {
                              fData.pQuantity = fData.pQuantity.filter((obj) => obj !== elem);
                              setSizes({
                                ...sizes,
                                size: "",
                              })
                              console.log("removed:",elem)
                            }}
                          >
                             <svg
                              className="w-4 h-4"
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M6 18L18 6M6 6l12 12"
                              />
                            </svg>
                          </div>
                        </div>
                      );
                    }))
                    : (<p style={{color: "lightgray"}}>Please select a size</p>)}
                </div>
                <div className="flex flex-row space-x-1">
                  <select
                    value={sizes.size}
                    onChange={(e) =>
                      setSizes({
                        ...sizes,
                        size: e.target.value,
                      })
                    }
                    name="status"
                    className="w-1/2 px-4 py-2 border focus:outline-none"
                    id="status"
                  >
                    <option disabled value="">
                     sizes
                    </option>
                    <option name="status" value="XS">
                      XS
                    </option>
                    <option name="status" value="S">
                      S
                    </option>
                    <option name="status" value="M">
                      M
                    </option>
                    <option name="status" value="L">
                      L
                    </option>
                    <option name="status" value="XL">
                      XL
                    </option>
                    <option name="status" value="XXL">
                      XXL
                    </option>
                    <option name="status" value="Free Size">
                      Free Size
                    </option>
                    
                  </select>
                  <input
                    value={sizes.quantity}
                    placeholder="0"
                    onChange={(e) =>
                      setSizes({
                        ...sizes,
                        quantity: e.target.value,
                      })
                    }
                    type="number"
                    className="w-1/2 px-4 py-2 border focus:outline-none"
                    id="quantity"
                  />
                  <Button variant="outlined"  onClick={()=>{
                    if(sizes.size && sizes.quantity){
                      fData.pQuantity.push(sizes)
                      setSizes({
                        ...sizes,
                        size:"",
                        quantity:"",
                      })
                    }
                  }}> Add</Button>
                </div>
              </div>

              <div>
                {fData.pQuantity.map((item, index) => (
                  <div key={index}>
                    <span>{item.size}: {item.quantity}</span>
                    <button onClick={() => handleRemoveQuantity(index)}>Remove</button>
                  </div>
                ))}
              </div>
            <div className="flex flex-col space-y-1 py-4">
              <label htmlFor="similar">Similar Products *</label>
                   <Select
                    ref={selectInputRef}
                    className="w-full"
                    placeholder="Select Similar Products"
                    onChange={handleSimilarChange}
                    options={similarOptions} 
                    isMulti
                   />
            
                  {/* <Button variant="outlined"  onClick={()=>{
                    if(similar){
                      fData.similarProducts=similar
                      console.log("line_444:",fData.similarProducts);
                      setSimilar(null)
                    }
                  }}> Link</Button> */}
                </div>
              {/* </di> */}
            </div>
            {/* Most Important part for uploading multiple image */}
            <div className="flex space-x-1 py-4">
              <div className="w-1/2 flex flex-col space-y-1">
                <label htmlFor="store">Store Name *</label>
  
                   <Select
                   options={optionsStore}
                   isSearchable
                   value={storeId}
                   onChange={handleStoreChange}
                   name="store"
                   className="px-4 py-2 border focus:outline-none"
                   id="store"
                   />
              </div>
              <div className="w-1/2 flex flex-col space-y-1">
                <label htmlFor="store">Section Name *</label>
                 <Select
                  options={sections}
                  isSearchable
                  value={sectionId}
                  onChange={handleSectionChange}
                  name="section"
                  className="px-4 py-2 border focus:outline-none"
                  id="section"
                />
              </div>
              
              <div className="w-1/2 flex flex-col space-y-1">
                <label htmlFor="status">Product Category *</label>
                 <Select
                    options={categories}
                    isSearchable
                    value={categoryId}
                    onChange={handleCategoryChange}
                    name="category"
                    className="px-4 py-2 border focus:outline-none"
                    id="category"
                  />
              </div>
              
            </div>
            <div className="flex space-x-1 py-4">
              <div className="w-1/2 flex flex-col space-y-1">
                <label htmlFor="status">Product Status *</label>
                <select
                  value={fData.pStatus}
                  onChange={(e) =>
                    setFdata({
                      ...fData,
                      error: false,
                      success: false,
                      pStatus: e.target.value,
                    })
                  }
                  name="status"
                  className="px-4 py-2 border focus:outline-none"
                  id="status"
                >
                  <option name="status" value="Active">
                    Active
                  </option>
                  <option name="status" value="Disabled">
                    Disabled
                  </option>
                </select>
              </div>
              <div className="w-1/2 flex flex-col space-y-1">
                <label htmlFor="offer">Product Offfer (%) *</label>
                <input
                  value={fData.pOffer}
                  onChange={(e) =>
                    setFdata({
                      ...fData,
                      error: false,
                      success: false,
                      pOffer: e.target.value,
                    })
                  }
                  type="number"
                  className="px-4 py-2 border focus:outline-none"
                  id="offer"
                />
              </div>
            </div>
            <div className="flex flex-col space-y-1 w-full pb-4 md:pb-6 mt-4">
              <button
                style={{ background: "#303031" }}
                type="submit"
                className="rounded-full bg-gray-800 text-gray-100 text-lg font-medium py-2"
              >
                Create product
              </button>
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

  

export default AddProductModal;
