import React, { Fragment, useState, useEffect } from "react";
import { getAllBlogs } from "../../admin/blogs/FetchApi";
import "./style.css";
import { prevSlide, nextSlide } from "./Mixins";

const BlogList = (props) => {
  const [slide, setSlide] = useState(0);
  const [blogs, setBlogs] = useState(null);

  const fetchData = async () => {
    try {
      let responseData = await getAllBlogs();
      if (responseData && responseData.Blogs) {
        setBlogs(responseData.Blogs);
      }
    } catch (error) {
      console.log(error);
    }
  };
  
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="my-4">
      <hr />
      <div className="relative blog-content">
        {blogs && blogs.length > 0 ?(
          <img
            className="w-full blogImage"
            src={`${blogs[slide].url}`}
            alt="Blog"
          />
        ):(
          ""
        )}
        <div className="z-10 absolute blogTitle">
          {/* <h1>{blogs && blogs[slide].bName}</h1> */}
          
        </div>
        <svg
          onClick={(e) => prevSlide(blogs.length, slide, setSlide)}
          className={`z-10 absolute top-50 left-0 mt-0 flex justify-end items-center box-border flex justify-center w-12 h-12 text-gray-700  cursor-pointer hover:text-yellow-700`}
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M15 19l-7-7 7-7"
          />
        </svg>
        <svg
          onClick={(e) => nextSlide(blogs.length, slide, setSlide)}
          className={`z-10 absolute top-50 right-0 mt-0 flex justify-start items-center box-border flex justify-center w-12 h-12 text-gray-700 cursor-pointer hover:text-yellow-700`}
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M9 5l7 7-7 7"
          />
        </svg>
      </div>
    </div>
  );
};

const Blog = (props) => {
  return (
    <Fragment>
      <BlogList />
    </Fragment>
  );
};

export default Blog;
