import React, { Fragment, useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Alert, reviewSubmitHanlder } from "./Action";
import { LayoutContext } from "../layout";
import { isAuthenticate } from "../auth/fetchApi";
import { getSingleProduct } from "./FetchApi";

const ReviewForm = () => {
  const { data, dispatch } = useContext(LayoutContext);
  let { id } = useParams(); // Product Id

  const [fData, setFdata] = useState({
    rating: "",
    review: "",
    error: false,
    success: false,
    pId: id,
  });

  useEffect(() => {
    if (fData.error || fData.success) {
      const timer = setTimeout(() => {
        setFdata({ ...fData, error: false, success: false });
      }, 3000);
      return () => clearTimeout(timer); // Clean up the timer on component unmount
    }
  }, [fData.error, fData.success]);

  useEffect(() => {
    fetchData();
  }, [id]);

  const fetchData = async () => {
    try {
      let responseData = await getSingleProduct(id);
      if (responseData.Product) {
        // Handle parsing of pQuantity and similarProducts
        if (!responseData.Product.pQuantity[0]?.size) {
          responseData.Product.pQuantity = JSON.parse(responseData.Product.pQuantity);
        }
        if (responseData.Product.similarProducts?.length > 0) {
          responseData.Product.similarProducts = JSON.parse(responseData.Product.similarProducts);
        }
        dispatch({
          type: "singleProductDetail",
          payload: responseData.Product,
        });
      }
      if (responseData.error) {
        console.log(responseData.error);
      }
    } catch (error) {
      console.log(error);
    }
  };


  // Get user authentication info
  const user = isAuthenticate()?.user;
  const userId = user?._id;

  console.log("Rating user _id:", user);
  console.log("Authenticated user _id:", isAuthenticate()?.user?._id);

  // Create the list of users who have rated the product

  console.log("Authenticated Usdafdaer Data:", isAuthenticate());

  const ratingUserList =
    Array.isArray(data?.singleProductDetail?.pRatingsReviews) &&
    data.singleProductDetail.pRatingsReviews.map((item) => item?.user?._id || "");

  return (
    <Fragment>
      <div className="md:mx-16 lg:mx-20 xl:mx-24 flex flex-col">
        {fData.error ? Alert("red", fData.error) : ""}
        {fData.success ? Alert("green", fData.success) : ""}
      </div>
      {ratingUserList?.length > 0 ? (
        ratingUserList.includes(userId) ? (
          <div className="mb-12 md:mx-16 lg:mx-20 xl:mx-24"></div>
        ) : (
          <div className="mb-12 md:mx-16 lg:mx-20 xl:mx-24 flex flex-col">
            <div className="flex flex-col space-y-2">
              <span className="text-2xl font-medium">Add a review</span>
              <span className="text-gray-600 text-sm">
                Your email address will not be published. Required fields are marked *
              </span>
            </div>
            {/* Input Rating */}
            <div className="mb-4">
              <fieldset
                onChange={(e) => setFdata({ ...fData, rating: e.target.value })}
                className="rating"
              >
                <input type="radio" id="star5" name="rating" value={5} />
                <label htmlFor="star5" title="Awesome - 5 stars" />
                <input type="radio" id="star4" name="rating" value={4} />
                <label htmlFor="star4" title="Pretty good - 4 stars" />
                <input type="radio" id="star3" name="rating" value={3} />
                <label htmlFor="star3" title="Meh - 3 stars" />
                <input type="radio" id="star2" name="rating" value={2} />
                <label htmlFor="star2" title="Kinda bad - 2 stars" />
                <input type="radio" id="star1" name="rating" value={1} />
                <label htmlFor="star1" title="Sucks big time - 1 star" />
              </fieldset>
            </div>
            {/* Review Form */}
            <div className="space-y-4">
              <div className="flex flex-col">
                <label htmlFor="textArea">
                  Review <span className="text-sm text-gray-600">*</span>
                </label>
                <textarea
                  onChange={(e) => setFdata({ ...fData, review: e.target.value })}
                  value={fData.review}
                  className="border px-4 py-2 focus:outline-none"
                  name="textArea"
                  id="textArea"
                  cols={30}
                  rows={3}
                  placeholder="Your review..."
                />
              </div>
              <div
                onClick={(e) => reviewSubmitHanlder(fData, setFdata, fetchData)}
                style={{ background: "#303031" }}
                className="inline-block rounded px-4 py-2 text-white text-center cursor-pointer"
              >
                Submit
              </div>
            </div>
          </div>
        )
      ) : (
        <div className="mb-12 md:mx-16 lg:mx-20 xl:mx-24">
          <p>No reviews yet. Be the first to add a review!</p>

          <div className="mb-12 md:mx-16 lg:mx-20 xl:mx-24 flex flex-col">
              <div className="flex flex-col space-y-2">
                <span className="text-2xl font-medium">Add a review</span>
                <span className="text-gray-600 text-sm">
                  Your email address will not be published. Required fields are
                  marked *
                </span>
              </div>
              {/* Input Rating */}
              <div className="mb-4">
                <fieldset
                  onChange={(e) => setFdata({ ...fData, rating: e.target.value })}
                  className="rating"
                >
                  <input type="radio" id="star5" name="rating" value={5} />
                  <label htmlFor="star5" title="Awesome - 5 stars" />
                  <input type="radio" id="star4" name="rating" value={4} />
                  <label htmlFor="star4" title="Pretty good - 4 stars" />
                  <input type="radio" id="star3" name="rating" value={3} />
                  <label htmlFor="star3" title="Meh - 3 stars" />
                  <input type="radio" id="star2" name="rating" value={2} />
                  <label htmlFor="star2" title="Kinda bad - 2 stars" />
                  <input type="radio" id="star1" name="rating" value={1} />
                  <label htmlFor="star1" title="Sucks big time - 1 star" />
                </fieldset>
              </div>
              {/* Review Form */}
              <div className="space-y-4">
                <div className="flex flex-col">
                  <label htmlFor="textArea">
                    Review <span className="text-sm text-gray-600">*</span>
                  </label>
                  <textarea
                    onChange={(e) => setFdata({ ...fData, review: e.target.value })}
                    value={fData.review}
                    className="border px-4 py-2 focus:outline-none"
                    name="textArea"
                    id="textArea"
                    cols={30}
                    rows={3}
                    placeholder="Your review..."
                  />
                </div>
                <div
                  onClick={(e) => reviewSubmitHanlder(fData, setFdata, fetchData)}
                  style={{ background: "#303031" }}
                  className="inline-block rounded px-4 py-2 text-white text-center cursor-pointer"
                >
                  Submit
                </div>
              </div>
            </div>
        </div>
      )}
    </Fragment>
  );
};

export default ReviewForm;
