import React, { Fragment, useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { wishListProducts, getCartItems, removeProductFromWishlist } from "./FetchApi";
import { LayoutContext } from "..";
import { cartListProduct } from "../partials/FetchApi";
import { totalCost } from "../partials/Mixins";
import { addToCart } from "../productDetails/Mixins";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { loginReq } from "../auth/fetchApi";
const apiURL = process.env.REACT_APP_API_URL;

const Product = (alignment) => {
  const history = useHistory();
  const { data: layoutData, dispatch: layoutDispatch } =
    useContext(LayoutContext);
  const [quantitiy, setQuantitiy] = useState(1);
  const [alertQ, setAlertq] = useState(false);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedSizeIndex, setSelectedSizeIndex] = useState([]);
  const [selectedSize, setSelectedSize] = useState([])
  let sizes = null;


  //TODO :  Add size to cart which is currently not present
  //TODO :  Instead of "IN CART", make it a button or something to change the quantity there itself
  //TODO : Change the UI.

  const handleSizeChange = (event, index) => {
    const newSelectedSize = [...selectedSize];
    newSelectedSize[index] = event.target.value; // Update the size for the product at the given index
    setSelectedSize(newSelectedSize);
  };

  const checkSizeAlreadyInCart = (productId, size) => {
    return layoutData.cartItems?.some((item) => {
      return item.productId === productId && item.productSize === size;
    });
  };

  useEffect(() => {
    if (sizes && sizes.length > 0) {
      setSelectedSize(sizes[0].size); // Automatically select the first size by default
    }
  }, [sizes]);

  const fetchCartProduct = async () => {
    try {
      let cartItems = await getCartItems();
      cartItems = cartItems.cartProducts || [];
      const carts = cartItems.map((cart) => cart.productId);
      layoutDispatch({ type: "inCart", payload: carts })
    } catch (error) {
      console.log(error);
    }
  };

  const fetchData = async () => {
    setLoading(true);
    let responseData = await wishListProducts();
    setTimeout(() => {
      if (responseData && responseData.Products) {
        setProducts(responseData.Products);
        // selectedSizeIndex =Array(products.length).fill(0) ;
        const defaultSizes = responseData.Products.map((product) => {
          const sizes = JSON.parse(product.pQuantity);
          return sizes.length > 0 ? sizes[0].size : null;
        });
        setSelectedSize(defaultSizes);
        setLoading(false);
      }
    }, 50);
    fetchCartProduct();
  };
  useEffect(() => {
    fetchData();
  }, []);

  const removeFromWishList = async (id) => {
    const responseData = await removeProductFromWishlist(id)
    fetchData();
  };
  if (loading) {
    return (
      <div className="my-32 text-2xl text-center">
        No product found in wishList
      </div>
    );
  }
  console.log("90:",alignment);
  return (
    <Fragment>
      <div className="grid grid-cols-2 md:grid-cols-5" >
        {(products.length > 0 && alignment.alignment === "stores") ? (
          products.map((product, index) => {
            sizes = JSON.parse(product.pQuantity);
            // console.log("Product Quantity : ", product);
            return (
              <div
                key={product._id}
                style={{backgroundColor: "#C24728"}}
                className="flex flex-col w-11/12 relative m-2 rounded-2xl overflow-hidden md:border-t md:border-b  col-span-1 "
              >
                <div className="md:items-center">
                  <img
                    onClick={(e) => history.push(`/products/${product._id}`)}
                    //onClick={(e) => history.push(`/products/${product.pName}`)}
                    className="cursor-pointer object-cover object-center"
                    src={`${product.url[0]}`}
                    alt="wishListproduct">
                  </img>
                  
                </div>
                <div className=" w-full px-4 text-xs md:text-base md:items-center">
                  <div className=" text-white text-center truncate" >
                    {product.pName}
                  </div>
                  
                </div>
                <div className=" justify-between w-full text-xs md:text-sm flex px-4  md:items-center">
                  <select value={selectedSize[index]} className="text-s" style={{backgroundColor: "#C24728"}} onChange={(e) => handleSizeChange(e, index)}>
                    {sizes.map((size, idx) => (
                      
                      <option value={size.size} className="text-s"  key={idx}>
                        {size.size}
                      </option>
                    ))}
                  </select>
                  <div className="font-semibold text-xs md:text-base text-gray-100">
                    &#8377;{product.pPrice}
                  </div>
                </div>
                <div className=" md:flex ">
                  {layoutData.inCart != null &&
                    layoutData.inCart.includes(product._id) === true &&
                    checkSizeAlreadyInCart(product._id,selectedSize[index]) ? (
                    <div
                      className={`px-4 py-2 w-3/5 text-white text-center text-xs md:text-sm cursor-not-allowed uppercase opacity-75`}
                    >
                      In cart
                    </div>
                  ) : (
                    <div
                      // style={{ background: "#303031" }}
                      onClick={(e) => {
                        addToCart(
                          product._id,
                          quantitiy,
                          product.pPrice,
                          product.pStore,
                          selectedSize[index],
                          product.url[0],
                          product.pName,
                          layoutData,
                          layoutDispatch,
                          setQuantitiy,
                          setAlertq,
                          fetchData,
                          totalCost
                        );
                      }}
                      className="inline-block w-3/5 px-4 py-2 text-white text-xs md:text-sm  text-center cursor-pointer hover:opacity-75"
                    >
                      Add to cart
                    </div>
                  )}

                  <div
                    onClick={async (e) => await removeFromWishList(product._id)}
                    style={{backgroundColor: "#BD725F"}}
                    className=" w-2/5 inline-block px-4 py-2 text-xs md:text-sm pl-0 text-center cursor-pointer hover:opacity-50"
                  >
                    Remove
                  </div>
                </div>
                {/* <div className="absolute top-0 right-0 mx-2 my-2 md:relative"></div> */}
              </div>
            );
          })
        ) : (
          <div>No product found in wishList</div>
        )}
      </div>
    </Fragment>
  );
};

const SingleWishProduct = (props) => {

  const [alignment, setAlignment] = React.useState('stores');
  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };
  return (
    <Fragment>
      <section className="mx-4 mt-20 md:mx-12 md:mt-48">
        <div className="flex justify-between">
          <div className="text-3xl font-bold tracking-wider md:tracking-widest2  mx-2 mb-12" style={{color: "#C24728"}}>Wishlist</div>
          <div> 
            <ToggleButtonGroup
              color="primary"
              value={alignment}
              exclusive
              onChange={handleChange}
              aria-label="Platform"
            >
              <ToggleButton  value="stores">Stores</ToggleButton>
              <ToggleButton  value="products">Products</ToggleButton>
            </ToggleButtonGroup>
          </div>
        </div>
        {/* Product List */}
        <Product alignment={alignment} />
      </section>
    </Fragment>
  );
};

export default SingleWishProduct;
