import React, { Fragment, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { LayoutContext } from "../index";
import { cartListProduct, getCartItems, removeSingleItemFromCart } from "./FetchApi";
import { isAuthenticate } from "../auth/fetchApi";
import { cartList, updateStoreWiseProducts } from "../productDetails/Mixins";
import { subTotal, quantity, totalCost, applyCouponsIfAvailable } from "./Mixins";

const apiURL = process.env.REACT_APP_API_URL;

//TODO no need to update data.cartProduct and inCart

const CartModal = (props) => {
  const history = useHistory();

  const { data, dispatch } = useContext(LayoutContext);

  const cartModalOpen = () =>
    dispatch({ type: "cartModalToggle", payload: !data.cartModal });

  useEffect(() => {
    fetchData();
  }, [data.cartModal]);

  const getCartListProducts = async (cartItems) => {
    let productArray = [];
    if (cartItems) {
      for (const cart of cartItems) {
        productArray.push(cart.productId);
      }
    }

    const responseData = await cartListProduct(productArray);
    return responseData;
  }


  const fetchData = async () => {

    try {
      let cartItems = await getCartItems();
      cartItems = cartItems.cartProducts || [];
      let responseData = await getCartListProducts(cartItems);
      // console.log("44:",cartItems);
      setTimeout(() => {
        dispatch({ type: "cartItems", payload: cartItems })
        if (responseData && responseData.Products) {

          dispatch({ type: "cartProduct", payload: responseData.Products });

          const storeWiseProducts = updateStoreWiseProducts(cartItems);
          const afterApplyingCoupon = applyCouponsIfAvailable(data, storeWiseProducts);

          dispatch({ type: "storeWiseProducts", payload: afterApplyingCoupon });
          dispatch({ type: "cartTotalCost", payload: totalCost(data) });

        }
        dispatch({ type: "loading", payload: false })
      }, 500)

    } catch (error) {
      console.log(error);
    }
  };

  const removeCartProduct = async (id,size) => {
    let cartItems = await removeSingleItemFromCart(id,size)
    cartItems = cartItems.cartProducts || []
    dispatch({ type: "cartItems", payload: cartItems })

    if (cartItems.length != 0) {
      const storeWiseProducts = updateStoreWiseProducts(cartItems);
      dispatch({ type: "storeWiseProducts", payload: storeWiseProducts });
      dispatch({ type: "resetAppliedCoupons", payload: [] })
      
      
      dispatch({ type: "inCart", payload: cartList(cartItems) });
      //await fetchData();
    }

    if (cartItems.length === 0) {
      dispatch({ type: "cartProduct", payload: null });
      dispatch({ type: "storeWiseProducts", payload: [] });
      dispatch({ type: "cartTotalCost", payload: 0 });
      dispatch({ type: "inCart", payload: [] });
      await fetchData();
    }
  };

  return (
    <Fragment>
      {/* Black Overlay */}
      <div
        className={`${!data.cartModal ? "hidden" : ""
          } fixed top-0 z-30 w-full h-full bg-black opacity-50`}
      />
      {/* Cart Modal Start */}
      <section
        className={`${!data.cartModal ? "hidden" : ""
          } fixed z-40 inset-0 flex items-start justify-end`}
      >
        <div
          style={{ background: "#303031" }}
          className="w-full md:w-5/12 lg:w-4/12 h-full flex flex-col justify-between"
        >
          <div className="overflow-y-auto">
            <div className="border-b border-gray-700 flex justify-between">
              <div className="p-4 text-white text-lg font-semibold">Cart</div>
              {/* Cart Modal Close Button */}
              <div className="p-4 text-white">
                <svg
                  onClick={(e) => cartModalOpen()}
                  className="w-6 h-6 cursor-pointer"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
            </div>
            <div className="m-4 flex-col">
              {data.cartItems &&
                data.cartItems.length !== 0 &&
                !data.cartItems.error &&
                data.cartItems.map((item, index) => {
                  // console.log("131:",item);
                  return (
                    <Fragment key={index}>
                      {/* Cart Product Start */}
                      <div className="text-white flex space-x-2 my-4 items-center">
                        <img
                          className="w-16 h-16 object-cover object-center"
                          src={item.url[0]}
                          alt="cartProduct"
                        />
                        <div className="relative w-full flex flex-col">
                          <div className="my-2">{item.productName}</div>
                          <div className="flex items-center justify-between">
                            <div className="flex items-center justify-between space-x-2">
                              <div className="text-sm text-gray-400">
                                Quantity :
                              </div>
                              <div className="flex items-end">
                                <span className="text-sm text-gray-200">
                                  {item.productQuantity}
                                </span>
                              </div>
                            </div>

                            <div className="flex items-center justify-between space-x-2">
                              <div className="text-sm text-gray-400">
                                Size :
                              </div>
                              <div className="flex items-end">
                                <span className="text-sm text-gray-200">
                                  {item.productSize}
                                </span>
                              </div>
                            </div>

                            <div>
                              {" "}
                              <span className="text-sm text-gray-400">
                                Subtotoal :
                              </span>{" "}
                              &#8377;{subTotal(item.productId, item.productPrice, data)}
                            </div>{" "}
                            {/* SUbtotal Count */}
                          </div>
                          {/* Cart Product Remove Button */}
                          <div
                            onClick={(e) => removeCartProduct(item.productId,item.productSize)}
                            className="absolute top-0 right-0 text-white"
                          >
                            <svg
                              className="w-5 h-5 cursor-pointer"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>
                      {/* Cart Product Start */}
                    </Fragment>
                  );
                })}

              {data.cartItems.length === 0 && (
                <div className="m-4 flex-col text-white text-xl text-center">
                  No product in cart
                </div>
              )}
            </div>
          </div>
          <div className="m-4 space-y-4">
            <div
              onClick={(e) => cartModalOpen()}
              className="cursor-pointer px-4 py-2 border border-gray-400 text-white text-center cursor-pointer"
            >
              Continue shopping
            </div>
            {data.cartTotalCost ? (
              <Fragment>
                {isAuthenticate() ? (
                  <div
                    className="px-4 py-2 bg-black text-white text-center cursor-pointer"
                    onClick={(e) => {
                      history.push("/checkout");
                      cartModalOpen();
                    }}
                  >
                    Checkout &#8377;{totalCost(data)}
                  </div>
                ) : (
                  <div
                    className="px-4 py-2 bg-black text-white text-center cursor-pointer"
                    onClick={(e) => {
                      history.push("/home");
                      cartModalOpen();
                      dispatch({
                        type: "loginSignupError",
                        payload: !data.loginSignupError,
                      });
                      dispatch({
                        type: "loginSignupModalToggle",
                        payload: !data.loginSignupModal,
                      });
                    }}
                  >
                    Checkout &#8377;{data.cartTotalCost}
                  </div>
                )}
              </Fragment>
            ) : (
              <div className="px-4 py-2 bg-black text-white text-center cursor-not-allowed">
                Checkout
              </div>
            )}
          </div>
        </div>
      </section>
      {/* Cart Modal End */}
    </Fragment>
  );
};

export default CartModal;
