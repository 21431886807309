import axios from "axios";
const apiURL = process.env.REACT_APP_API_URL;

const BearerToken = () =>
  localStorage.getItem("jwt")
    ? JSON.parse(localStorage.getItem("jwt")).token
    : false;
const Headers = () => {
  return {
    headers: {
      token: `Bearer ${BearerToken()}`,
    },
  };
};

export const addItemToCart = async(data)=>{
  try{
    let res = await axios.post(`${apiURL}/api/cart/add-product`, data, { withCredentials: true });
    return res.data;
  }catch(err){
    console.log(err)
  }
}

export const getCartItems = async()=>{
  try{
    let res = await axios.get(`${apiURL}/api/cart/get-cart-items`,  { withCredentials: true });
    return res.data;
  }catch(err){
    console.log("31err:",err)
  }
}

export const removeSingleItemFromCart = async(productId,productSize)=>{
  try{
    let res = await axios.post(`${apiURL}/api/cart/remove-cart-item`, {productId,productSize}, { withCredentials: true });
    return res.data;
  }catch(err){
    console.log(err)
  }
}

export const cartListProduct = async (productArray) => {
  try {
    if(productArray.length!==0){
      let res = await axios.post(`${apiURL}/api/product/cart-product`, {
        productArray,
      }, { withCredentials: true });
      return res.data
    }
    return null   
  } catch (error) {
    console.log(error);
  }
};
